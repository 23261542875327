'use strict';

Gri.module({
  name: 'form-group-alpha',
  ieVersion: null,
  $el: $('.form-group-alpha'),
  container: '.form-group-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let $radioArea = $this.find('.radio-area');
    // endregion

    // region run

    // endregion

    // region events
    $radioArea.find('label').on('click', function () {
      $radioArea.find('label').removeClass('clicked');
      $(this).addClass('clicked');
    });
    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion

    $('[type="radio"]:not(.noticheck)').iCheck({
      radioClass: 'radio-style'
    }).on('ifClicked', function () {
      $radioArea.find('label').removeClass('clicked');
      $(this).parent().prev().addClass('clicked');
    });

  }
});
